<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/admin/home">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Reports") }}</label>
    </div>

    <v-toolbar-title class="mt-3">التقارير</v-toolbar-title>

    <v-container fluid>
      <v-row>
        <v-col md="3">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.report'),
            }"
          >
            <div class="mt-2">
              <label class="control-label">اسم التقرير</label>
            </div>
            <v-autocomplete
              class="d-block mt-2"
              name="report"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('Report Name')"
              hide-details
              dense
              outlined
              v-model="addEditObj.report"
              item-value="id"
              item-text="name"
              :items="listReport"
              clearable
              @change="clearData"
            ></v-autocomplete>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.report')"
            >
              {{ errors.first("addEditValidation.report") }}
            </div>
          </div>
        </v-col>

        <!-- <v-col md="2" class="mt-2" v-if="addEditObj.report == 4">
          <div
            class="form-group"
          >
            <label class="control-label">{{ $t("Page") }}</label>
            <div>
              <el-input
                type="number"
                min="1"
                name="page"
                class="form-control mt-2"
                style="width: 100%"
                v-model="addEditObj.page"
              ></el-input>
            </div>
          </div>
        </v-col> -->

        <v-col md="2" class="mt-2" v-if="addEditObj.report == 9">
          <div class="form-group">
            <label class="control-label">{{ $t("RemainBalance") }}</label>
            <div>
              <el-input
                type="text"
                name="page"
                class="form-control mt-2"
                style="width: 100%"
                v-model="addEditObj.balance"
              ></el-input>
            </div>
          </div>
        </v-col>

        <v-col
          md="3"
          class="mt-2"
          v-if="
            addEditObj.report !== 4 &&
            addEditObj.report !== 9 &&
            addEditObj.report !== 10
          "
        >
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.cooking_date'),
            }"
          >
            <label class="control-label">{{ $t("Date") }}</label>
            <div>
              <el-date-picker
                type="date"
                name="cooking_date"
                class="form-control mt-2"
                style="width: 100%"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('Cooking Date')"
                v-model="addEditObj.date"
              ></el-date-picker>
            </div>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.cooking_date')"
            >
              {{ errors.first("addEditValidation.cooking_date") }}
            </div>
          </div>
        </v-col>

        <v-col md="3" class="mt-2" v-if="addEditObj.report == 10">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.from_date'),
            }"
          >
            <label class="control-label">{{ $t("FromDate") }}</label>
            <div>
              <el-date-picker
                type="date"
                name="from_date"
                class="form-control mt-2"
                style="width: 100%"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('FromDate')"
                v-model="addEditObj.from_date"
              ></el-date-picker>
            </div>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.from_date')"
            >
              {{ errors.first("addEditValidation.from_date") }}
            </div>
          </div>
        </v-col>

        <v-col md="3" class="mt-2" v-if="addEditObj.report == 10">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.to_date'),
            }"
          >
            <label class="control-label">{{ $t("ToDate") }}</label>
            <div>
              <el-date-picker
                type="date"
                name="to_date"
                class="form-control mt-2"
                style="width: 100%"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('ToDate')"
                v-model="addEditObj.to_date"
              ></el-date-picker>
            </div>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.to_date')"
            >
              {{ errors.first("addEditValidation.to_date") }}
            </div>
          </div>
        </v-col>

        <v-col :md="addEditObj.report == 10 ? 3 : 6" class="mt-9 mx-0">
          <el-button @click="downloadExcel" type="success"
            >تصدير اكسل
          </el-button>

          <el-button
            v-if="addEditObj.report == 3"
            class="mx-2"
            @click="goToExportPdf()"
            type="primary"
            >تصدير PDF & EXCEL
          </el-button>
        </v-col>
      </v-row>
      <v-row v-loading="loading" class="mt-5"></v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      addEditObj: {
        date: null,
        from_date: null,
        to_date: null,
        report: null,
        page: 1,
        balance: null,
      },
      loading: false,
      listReport: [],
    };
  },
  methods: {
    clearData() {
      this.addEditObj.balance = null;
      this.addEditObj.date = null;
      this.addEditObj.from_date = null;
      this.addEditObj.to_date = null;
      this.$validator.reset();
    },
    goToExportPdf() {
      if (this.addEditObj.date) {
        localStorage.setItem(
          "quantiy_date_export",
          moment(this.addEditObj.date).format("YYYY-MM-DD")
        );
        const newTabUrl = this.$router.resolve({
          name: "quantityReportExport",
        }).href;
        window.open(newTabUrl, "_blank");
      } else {
        this.notifyCustomError(this.$t("Error"), "يجب اختيار التاريخ");
      }
    },
    downloadPDF() {},

    downloadExcel() {
      const reportConfigs = {
        1: {
          action: "report/export_kitchen_today_export",
          data: () => ({
            date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          }),
        },
        2: {
          action: "report/export_deliveries_export",
          data: () => ({
            date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          }),
        },
        3: {
          action: "report/export_quantities_today",
          data: () => ({
            date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          }),
        },
        4: {
          action: "report/export_subscription_data",
          data: () => ({}), // No additional data needed
        },
        5: {
          action: "report/export_renwal_data_export",
          data: () => ({
            date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          }),
        },
        6: {
          action: "report/export_kitchen_today_export1",
          data: () => ({
            date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          }),
        },
        7: {
          action: "report/export_customer_registration",
          data: () => ({
            date: this.addEditObj.date
              ? moment(this.addEditObj.date).format("YYYY-MM-DD")
              : "",
          }),
        },
        8: {
          action: "report/export_new_subscribes",
          data: () => ({
            date: this.addEditObj.date
              ? moment(this.addEditObj.date).format("YYYY-MM-DD")
              : "",
          }),
        },
        9: {
          action: "report/export_customers_balances",
          data: () => ({ balance: this.addEditObj.balance }),
        },
        10: {
          action: "report/export_compensations",
          data: () => ({
            from_date: moment(this.addEditObj.from_date).format("YYYY-MM-DD"),
            to_date: moment(this.addEditObj.to_date).format("YYYY-MM-DD"),
          }),
        },
      };

      const reportConfig = reportConfigs[this.addEditObj.report];

      if (reportConfig) {
        const send = reportConfig.data();
        this.loading = true;
        this.$store
          .dispatch(reportConfig.action, send)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.notifyCustomError(this.$t("Error"), error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.error(
          `No configuration found for report ID: ${this.addEditObj.report}`
        );
      }
    },
  },

  mounted() {
    // Define the mappings of permissions to report entries
    const reportMappings = [
      {
        permission: "export_kitchen_today",
        reports: [
          { id: 1, name: this.$t("CookingToday1") },
          { id: 6, name: this.$t("CookingToday2") },
        ],
      },
      {
        permission: "export_deliveries",
        reports: [{ id: 2, name: this.$t("DelegateToday") }],
      },
      {
        permission: "export_quantities_today",
        reports: [{ id: 3, name: this.$t("QuantitiesToday") }],
      },
      {
        permission: "export_subscription_data",
        reports: [{ id: 4, name: this.$t("SubscribtionsData") }],
      },
      {
        permission: "export_renwal_data",
        reports: [{ id: 5, name: this.$t("ResumeToday") }],
      },
      {
        permission: "export_renwal_data",
        // permission: "export_customer_details_registration",
        reports: [{ id: 7, name: this.$t("customer_details_registration") }],
      },
      {
        permission: "export_renwal_data",
        // permission: "export_new_subscribes",
        reports: [{ id: 8, name: this.$t("newSubscribes") }],
      },
      {
        permission: "export_renwal_data",
        // permission: "export_customers_balances",
        reports: [{ id: 9, name: this.$t("customersBalancesReport") }],
      },
      {
        permission: "export_renwal_data",
        // permission: "export_compensations ",
        reports: [{ id: 10, name: this.$t("Compensations") }],
      },
    ];

    // Check if user has the permissions and push the corresponding reports
    if (this.user && this.user.permissions) {
      reportMappings.forEach((mapping) => {
        if (this.user.permissions.includes(mapping.permission)) {
          this.listReport.push(...mapping.reports);
        }
      });
    }
  },
};
</script>
